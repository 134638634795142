<template>
  <div v-if="showButton" class="main-tickets-btn">
    <AButtonWrapper
      class="main-tickets-btn__btn-wrapper"
      :height="80"
    >
      <AText
        :size-lg="{ size: 24, line: 24 }"
        :size-sm="{ size: 22, line: 22 }"
      >
        Купить билет
      </AText>
    </AButtonWrapper>
  </div>
</template>

<script setup lang="ts">
import AText from '~/components/atoms/text/a-text.vue';
import AButtonWrapper from '~/components/atoms/button-wrapper/a-button-wrapper.vue';

interface Props {
  showButton: boolean,
}

const props = defineProps<Props>();

const showButton = ref(props.showButton);

</script>

<style scoped lang="postcss" src="./style.css" />
