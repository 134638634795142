<template>
  <div
    class="button-wrapper"
    :style="{
      backgroundImage: `url(${props.backgroundUrl})`,
      width: `${props.width}px`,
      height: `${props.height}px`,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
interface Props {
  backgroundUrl?: string,
  width?: number,
  height?: number,
}

const props = withDefaults(defineProps<Props>(), {
  backgroundUrl: '',
  width: 720,
  height: 75,
});

</script>

<style scoped lang="postcss" src="./style.css" />
