<template>
  <footer class="o-footer global-padding">
    <!--    <div class="o-footer__sponsers">-->
    <!--      <div class="o-footer__sponsers-item">-->
    <!--        <AText-->
    <!--          tag="div"-->
    <!--          class="sponsers__title"-->
    <!--          :size-lg="{ size: 16, line: 20 }"-->
    <!--          :size-sm="{ size: 12, line: 14 }"-->
    <!--          :uppercase="false"-->
    <!--          font-family="vk"-->
    <!--          weight="regular"-->
    <!--        >-->
    <!--          Титульный партнер-->
    <!--        </AText>-->
    <!--        <img src="@/assets/images/vtb.svg" :width="isMobile ? 123 : 130" alt="vtb">-->
    <!--      </div>-->
    <!--      <div class="o-footer__sponsers-item">-->
    <!--        <AText-->
    <!--          tag="div"-->
    <!--          class="sponsers__title"-->
    <!--          :size-lg="{ size: 16, line: 20 }"-->
    <!--          :size-sm="{ size: 12, line: 14 }"-->
    <!--          :uppercase="false"-->
    <!--          font-family="vk"-->
    <!--          weight="regular"-->
    <!--        >-->
    <!--          Генеральный партнер-->
    <!--        </AText>-->
    <!--        <img src="@/assets/images/omoda.svg" :width="isMobile ? 230 : 280" alt="omoda">-->
    <!--      </div>-->
    <!--      <div class="o-footer__sponsers-item">-->
    <!--        <img src="@/assets/images/omoda.svg" :width="isMobile ? 210 : 258" alt="omoda">-->
    <!--        <img src="@/assets/images/vtb.svg" :width="isMobile ? 110 : 120" alt="vtb">-->
    <!--        <img src="@/assets/images/omoda.svg" :width="isMobile ? 210 : 258" alt="omoda">-->
    <!--        <img src="@/assets/images/vtb.svg" :width="isMobile ? 110 : 120" alt="vtb">-->
    <!--        <img src="@/assets/images/omoda.svg" :width="isMobile ? 210 : 258" alt="omoda">-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="o-footer__content">
      <AText
        tag="div"
        class="o-footer__content-copyright"
        :size-lg="{ size: 16, line: 20 }"
        :size-sm="{ size: 16, line: 20 }"
        :uppercase="false"
        font-family="vk"
        weight="regular"
      >
        © 2006-2025. ВКонтакте
      </AText>

      <nav class="o-footer__content-menu">
        <AText
          v-for="item in menu"
          :key="item.title"
          tag="span"
          :size-lg="{ size: 16, line: 20 }"
          :size-sm="{ size: 16, line: 20 }"
          :uppercase="false"
          font-family="vk"
          weight="regular"
          :disabled="item.disabled"
        >
          <a
            v-if="item.type === 'widget'"
            style="cursor: pointer"
            @click="openWidget(item.link)"
          >
            {{ item.title }}
          </a>

          <NuxtLink
            v-else
            :to="item.disabled ? '#' : item.link"
          >
            {{ item.title }}
          </NuxtLink>
        </AText>
      </nav>

      <div class="o-footer__content-null-plus">
        <img src="@/assets/images/null-plus.svg" alt="ноль плюс">
      </div>

      <div class="o-footer__content-contacts">
        <div class="o-footer__content-contacts_social">
          <NuxtLink
            v-for="item in social"
            :key="item.name"
            :href="item.link"
            target="_blank"
            @click="sendAnalytics('fest_'+item.name)"
          >
            <img :src="item.icon" :alt="item.name">
          </NuxtLink>
        </div>

        <AText
          tag="div"
          class="o-footer__content-contacts_email"
          :size-lg="{ size: 16, line: 20 }"
          :size-sm="{ size: 16, line: 20 }"
          :uppercase="false"
          font-family="vk"
          weight="regular"
        >
          <span>info@vkfest.ru</span>
        </atext>
      </div>
    </div>

    <div class="o-footer__info">
      <div class="o-footer__info-logo">
        <img src="@/assets/images/art-leb.svg" width="170" alt="лого Лебедев">
      </div>
      <div class="o-footer__info-text">
        <AText
          tag="span"
          :size-lg="{ size: 16, line: 20 }"
          :size-sm="{ size: 16, line: 20 }"
          :uppercase="false"
          font-family="vk"
          weight="regular"
        >
          Задизайнено <br class="mobile-visible"> в <a href="https://artlebedev.com/" target="_blank">Cтудии Артемия Лебедева</a>
        </AText>
        <AText
          tag="span"
          :size-lg="{ size: 16, line: 20 }"
          :size-sm="{ size: 16, line: 20 }"
          :uppercase="false"
          font-family="vk"
          weight="regular"
        >
          <NuxtLink to="#">
            Информация о сайте
          </NuxtLink>
        </AText>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import AText from '~/components/atoms/text/a-text.vue';
import { SETTINGS, SHOWGOGO_SUPPORT_URL } from '~/constants';
import { isModalOpen, modalUrl, openWidget } from '~/composables/useWidget';
import { sendAnalytics } from '~/utils/useAnalytics';

const device = useDevice();

const isMobile = computed(() =>
  device.isMobile.value || device.isTablet.value
);

const menu = [
  {
    title: 'Правила посещения',
    link: '/rules',
    disabled: false,
  },
  {
    title: 'Оставить заявку на участие',
    link: '/contact-us',
    disabled: false,
  },
  {
    title: 'Поддержка по билетам',
    link: SHOWGOGO_SUPPORT_URL,
    type: 'widget',
    disabled: false,
  },
];

const social = [
  {
    name: 'vk',
    link: SETTINGS.vkLink,
    icon: '/assets/icons/vk-mini.svg',
  },
  {
    name: 'dzen',
    link: SETTINGS.dzenLink,
    icon: '/assets/icons/dzen-mini.svg',
  },
  {
    name: 'telegram',
    link: SETTINGS.telegramLink,
    icon: '/assets/icons/telegram-mini.svg',
  },
];

</script>

<style scoped lang="postcss" src="./style.css" />
