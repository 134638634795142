<template>
  <div ref="animationRef" class="m-animation">
    <div class="layer" />
    <div class="m-animation__wrapper">
      <img
        v-if="!hidePink"
        class="pink animated"
        :class="{ playing: isAnimating }"
        src="/assets/animations/images/pink.webp"
        alt="pink"
        loading="lazy"
      >
      <img
        class="green animated reverse delay-1"
        :class="{ playing: isAnimating }"
        src="/assets/animations/images/green.webp"
        alt="green"
        loading="lazy"
      >
      <img
        class="purple animated delay-2"
        :class="{ playing: isAnimating }"
        src="/assets/animations/images/purple.webp"
        alt="purple"
        loading="lazy"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

interface Props {
  hidePink?: boolean,
  bgColor?: 'black' | 'blue',
}

const props = withDefaults(defineProps<Props>(), {
  hidePink: false,
  bgColor: 'black',
});
const device = useDevice();
const isMobile = computed(() => device.isMobile.value);
const animationRef = ref<HTMLElement | null>(null);
let observer: IntersectionObserver | null = null;
const isAnimating = ref(false);

const bg_color = computed(() => {
  switch (props.bgColor) {
    case 'black': return 'var(--black-color)';
    case 'blue': return 'var(--blue-color)';
    default: return 'var(--black-color)';
  }
});

const onIntersect = (visible: boolean) => {
  isAnimating.value = visible;
};

const initObserver = () => {
  if (observer) return;
  observer = new IntersectionObserver(
    ([entry]) => onIntersect(entry.isIntersecting),
    { threshold: 0.1, rootMargin: isMobile.value ? '400px 0px 0px 0px' : '100px 0px 0px 0px' }
  );

  if (animationRef.value) {
    observer.observe(animationRef.value);
  }
};

onMounted(() => {
  initObserver();
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<style scoped lang="postcss">
.m-animation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.3);
  height: 600px;
  overflow: hidden;
  contain: layout paint size;

  .layer {
    position: absolute;
    bottom: -35%;
    left: 50%;
    width: inherit;
    height: 500px;
    transform: translateX(-50%);
    object-fit: cover;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: v-bind(bg_color);
      z-index: 0;

      -webkit-mask-image: url('/assets/animations/images/mask.png');
      mask-image: url('/assets/animations/images/mask.png');

      -webkit-mask-size: 120% auto;
      mask-size: 70% auto;
      -webkit-mask-position: top center;
      mask-position: top center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @media screen and (min-width: 2000px) {
        display: none;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: inherit;
    height: inherit;
    transform: perspective(2500px) rotateX(296deg);
    z-index: 2;

    @media screen and (max-width: 768px) {
      transform-style: flat;
    }

    @media screen and (min-width: 2500px) {
      transform: perspective(2000px) rotateX(295deg);
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    image-rendering: optimizeSpeed;
    backface-visibility: hidden;
    contain: layout;
    will-change: transform;
  }

  .pink { bottom: -150px; z-index: 4; }
  .green { bottom: -160px; z-index: 4; }
  .purple { bottom: -200px; z-index: 3; }

  .animated {
    animation: rotateLoop 16s steps(360) infinite;
    animation-play-state: paused;
  }

  .playing {
    animation-play-state: running;
  }

  .reverse {
    animation-direction: reverse;
  }

  .delay-1 {
    animation-delay: 0.1s;
  }

  .delay-2 {
    animation-delay: 0.6s;
  }

  @keyframes rotateLoop {
    0% { transform: rotate(0deg) translateZ(0); }
    100% { transform: rotate(360deg) translateZ(0); }
  }
}
</style>
