import { ref } from 'vue';
import useCommonStore from '~/store/common';
import type { ICity, TCity } from '~/types/api';
import { SHOWGOGO_SUPPORT_URL, CITY_MAP } from '~/constants';

export const isModalOpen = ref<boolean>(false);
export const modalUrl = ref('');

export const openWidget = (url: string, forceRedirectForMobile = false) => {
  if (!url) return;

  const params = new URLSearchParams(window.location.search);
  let queryString = params.toString();
  let finalUrl = queryString ? url + '&' + queryString : url;

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (forceRedirectForMobile && (isAndroid || isIOS)) {
    window.open(finalUrl, '_blank');
    return;
  }

  if (isAndroid) {
    window.open(finalUrl, '_blank');
    return;
  }

  modalUrl.value = finalUrl;
  isModalOpen.value = true;
};

const handleHash = () => {
  const commonStore = useCommonStore();

  const hash = window.location.hash;
  if (!hash) return;
  
  const match = hash.match(/widget=([^&]+)/);
  if (!match) return;

  if (match && Object.keys(CITY_MAP).includes(match[1] as keyof typeof CITY_MAP)) {
    const cityData = computed(() => commonStore.cities?.[match[1] as TCity] as ICity || {});
    openWidget(cityData.value.ticket_href);
  }

  if (match && match[1] == 'support') {
    openWidget(SHOWGOGO_SUPPORT_URL, true);
  }

};

setTimeout(handleHash, 500);
